import { useLocation } from '@remix-run/react';
import { AnalyticsBrowser, type MiddlewareFunction } from '@segment/analytics-next';
import { useEffect } from 'react';
import { useEnv } from '~/utils/env.js';
import { klaviyoIdentify, klaviyoTrack } from '~/utils/klaviyo.js';
import { useEvent } from '~/utils/use-event.js';
import { useOptionalUser } from '~/utils/user.js';

export const analytics = new AnalyticsBrowser();

export const SegmentTracker = () => {
  const env = useEnv();

  useEffect(() => {
    if (!env.SEGMENT_KEY) {
      console.warn('Analytics is not configured');
      return;
    }

    analytics.load(
      { writeKey: env.SEGMENT_KEY },
      {
        obfuscate: true,
      },
    );
  }, [env.SEGMENT_KEY]);

  if (!env.SEGMENT_KEY) {
    return null;
  }

  return (
    <>
      <IdentifyTracker />
      <PageTracker />
      <SegmentMiddleware />
    </>
  );
};

function IdentifyTracker() {
  const user = useOptionalUser();

  useEffect(() => {
    if (!user?.id) return;

    analytics.identify(user.id, {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      avatar: user.avatar?.url,
    });
  }, [user?.avatar?.url, user?.email, user?.firstName, user?.id, user?.lastName]);

  return null;
}

function PageTracker() {
  useEffect(() => {
    const category = location.pathname.startsWith('/app') ? 'app' : 'site';
    analytics.page(category, location.pathname, {}, {});
  }, []);

  return null;
}

function SegmentMiddleware() {
  const location = useLocation();

  const middleware = useEvent<MiddlewareFunction>(({ payload, next }) => {
    payload.obj.context ??= {};
    const context = payload.obj.context;

    const searchParams = new URLSearchParams(location.search);
    const impactClickId = searchParams.get('irclickid');
    if (impactClickId) {
      context.referrer = {
        type: 'impactRadius',
        id: impactClickId,
      };
    }

    if (payload.obj.type === 'identify') {
      const traits = payload.obj.traits;
      klaviyoIdentify({
        ...traits,
        id: undefined,
        externalId: traits?.id,
        image: traits?.avatar,
      });
    }

    if (payload.obj.type === 'track' && payload.obj.event) {
      klaviyoTrack(payload.obj.event, payload.obj.properties);
    }

    next(payload);
  });

  useEffect(() => {
    analytics.addSourceMiddleware(middleware);
  }, [middleware]);

  return null;
}
